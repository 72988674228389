import React from "react"
import "./icon.css"

export const LinkIcon = () => {
	return (
		<svg
			fill="none"
			viewBox="0 0 13 12"
			className="chakra-icon css-onkibi"
			xmlns="http://www.w3.org/2000/svg"
		>	
			<path
				d="M7.16669 0L9.36202 2.19533L4.69535 6.862L5.63802 7.80467L10.3047 3.138L12.5 5.33333V0H7.16669Z"
				fill="#347B61"
			/>
			<path
				d="M11.1667 10.6667H1.83333V1.33333H6.5L5.16667 0H1.83333C1.098 0 0.5 0.598 0.5 1.33333V10.6667C0.5 11.402 1.098 12 1.83333 12H11.1667C11.902 12 12.5 11.402 12.5 10.6667V7.33333L11.1667 6V10.6667Z"
				fill="#347B61"
			/>
		</svg>
	)
}
