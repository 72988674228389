export const defaultTextToSpeetch = `Input your text in that form, correct the text below, or check templates.

"Mia Rodriguez, the energy and will within you is boundless. Step into the gym with confidence, and you will uncover your strength."

"Every weight you lift is a testament to your inner power. Remember, Mia Rodriguez, it’s not just about physical strength but the power of your spirit."

"Calmness and poise, Mia Rodriguez. Remember, the journey of a thousand miles begins with a single step. Your path to strength and independence is paved with each workout."

"Each day, you grow more capable, Mia Rodriguez. All home chores are but challenges that you're more than equipped to conquer."

"Independence is a journey, Mia Rodriguez. With every gym session and every chore completed, you’re building resilience and self-reliance."

"Trust the process, Mia Rodriguez. Every drop of sweat, every moment spent, is a step closer to the strong and independent person you aspire to be."

"When you feel overwhelmed, pause and breathe, Mia Rodriguez. You possess the strength to face every task, big or small."

"Embrace the journey, Mia Rodriguez. Every weight lifted, every chore finished, is a testament to your determination."

"Your spirit is unbreakable, Mia Rodriguez. No task, in the gym or at home, is too daunting for you."

"Remember, Mia Rodriguez, it's not the mountain ahead that wears you out, but the pebble in your shoe. Stay focused, keep going, and you will triumph."

With each affirmation, imagine the calm, assuring voice of a wise mentor guiding you, reinforcing the truth in each statement. Each day is a step forward, Mia Rodriguez. Embrace the power within you.`

export const linkVoiceOverArtistsMyAffirmation = "https://lemondigital.notion.site/Voice-Over-Artists-MyAffirmation-ai-acb83e4627cf48749a2ceb9ad72af02c?pvs=4"
export const linkAffirmationTemplates = "https://lemondigital.notion.site/Affirmation-text-Templates-0a2edbbb9f9d480b881d6923dee4f42f?pvs=4"


export const typesAffirmation = ["Invigorating", "Calming"]
export const kindsAffirmation = [
    "Wealth",
    "Joy from little things",
    "Taking care of yourself",
    "Family and loved ones",
    "Mental health",
    "Self-esteem",
    "Exam preparation",
    "Sports"
]