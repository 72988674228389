import React from "react"

export const EmailIcon = () => {
	return (
		<svg
			width="21"
			height="16"
			viewBox="0 0 21 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18.5 0H4.5C3.397 0 2.5 0.897 2.5 2V7H4.5V4L10.9 8.8C11.0732 8.92967 11.2837 8.99975 11.5 8.99975C11.7163 8.99975 11.9268 8.92967 12.1 8.8L18.5 4V13H10.5V15H18.5C19.603 15 20.5 14.103 20.5 13V2C20.5 0.897 19.603 0 18.5 0ZM11.5 6.75L5.166 2H17.834L11.5 6.75Z"
				fill="#579881"
			/>
			<path
				d="M0.5 8H7.5V10H0.5V8ZM2.5 11H8.5V13H2.5V11ZM5.5 14H9.5V16H5.5V14Z"
				fill="#579881"
			/>
		</svg>
	)
}
